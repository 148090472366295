<template>
  <div class="consult">
    <Title />
    <section class="consult-container">
      <div class="privacy">
        <div class="privacy-title">
          {{ $t("agree_privacy_title") }}
        </div>

        <div class="privacy-desc">
          {{ $t("agree_privacy_desc") }}
        </div>

        <v-checkbox v-model="isPrivacy" :label="$t('agree_privacy_accept')">
        </v-checkbox>

        <div
          class="toConsult-button"
          @click="isPrivacy ? $router.push('/consult/1') : ''"
          :class="isPrivacy ? '' : 'disabled'"
        >
          <div class="toConsult-button-text">{{ $t("agree_button") }}</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Title from "../components/title/Title.vue";

export default {
  name: "Form0",
  components: {
    Title,
  },
  data() {
    return {
      isPrivacy: false,
    };
  },
  mounted() {},
  methods: {
    checkPrivacy() {
      if (this.isPrivacy) {
        this.$router.push("/consult/1");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.consult {
  height: 100%;
  width: 100%;
  font-family: "MS PGothic, 'Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;";

  .consult-container {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    background-blend-mode: lighten;
    background-size: cover;
    background-position: center;
  }

  .privacy {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin: 80px 10% 30px 10%;
    background-color: rgba(255, 255, 255);
    box-shadow: 0px 0px 30px 5px #e8f2f9;
    border-radius: 10px;

    .privacy-title {
      font-size: max(25px, 2.2vw);
      font-weight: bold;
      color: #132d7a;
      margin:30px ;
    }

    .privacy-desc {
      font-size: max(16px, 1.5vw);
      color: #000;
      font-weight: normal;
      white-space: pre-line;
      margin:30px 3%;
      z-index: 4;
    }

    .v-input--checkbox {
      transform: scale(1.5);
      z-index: 5;
    }

    .toConsult-button {
      margin: 30px;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      width: 40%;
      background-color: #132d7a;
      border-radius: 15px;
      cursor: pointer;
      transition: 0.5s;

      &.disabled {
        background-color: #ccc;
        cursor: text;
      }

      &:hover {
        opacity: 0.8;
      }

      .toConsult-button-text {
        color: #fafafa;
        font-size: max(15px, 1.6vw);
        font-weight: bold;
        letter-spacing: 10px;
        text-align: center;
      }
    }
  }
}
</style>
